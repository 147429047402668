import { createContext } from 'react';

export const AppContext = createContext();

export const AppContextData = {
    "domain": "gripitbysrushti.com",
    "name": "GRIPIT.FIT",
    "logo": "/homePageLogo.png",
    "information": {
        "email": "gripitbysrushti@gmail.com",
        "owner": "@ssrushti",
        "address": "SH4, Ground floor, Galaxy Arcade, Mahatma Gandhi Road, Vile Parle (East), Mumbai - 400057, Maharashtra"
    },
    "socialMedia": [{
        "platform": "email",
        "url": "mailto:gripitbysrushti@gmail.com",
        "text": "gripitbysrushti@gmail.com"
    }, {
        "platform": "instagram",
        "url": "https://www.instagram.com/ssrushti",
        "text": "@ssrushti"
    }, {
        "platform": "instagram",
        "url": "https://www.instagram.com/gripit.fit",
        "text": "@gripit.fit"
    }, {
        "platform": "whatsapp",
        "url": "https://wa.me/919410385170",
        "text": "+91-9410385170"
    }, {
        "platform": "youtube",
        "url": "https://www.youtube.com/@ssrushti/",
        "text": "@ssrushti"
    }, {
        "platform": "twitter",
        "url": "https://twitter.com/srushtishah2",
        "text": "@srushtishah2"
    }],
    "homePageData": {
        "title": "GRIPIT",
        "description": "Explore Srushti Rajesh Shah's inspiring journey from a computer engineer to a certified calisthenics coach. Learn how she's empowering women to embrace the art of calisthenics, fostering a vibrant community through her personal Instagram handle and initiatives like Calisthenics Girls India and Grip It.",
        "carouselImages": [{
            "url": "/images/homepage/retreat-banner.png",
            "alt": "Cali Escape",
            "caption": "",
            "ctaButton": {
                "text": "Know More",
                "link": "/programs/cali-retreat-feb25/cali-retreat-feb25"
            }
        },
        {
            "url": "/images/homepage/fbw-banner.png",
            "alt": "Programs we offer",
            "caption": "",
            "ctaButton": {
                "text": "Our Programs",
                "link": "/programs?tag=calisthenics-live"
            }
        }, {
            "url": "/images/homepage/founder-banner.png",
            "alt": "Srushti Rajesh Shah",
            "caption": "",
            "ctaButton": {
                "text": "Know More",
                "link": "/about-us"
            }
        },
        ],
        "otherSections": [{
            "title": "Why Calisthenics?",
            "description": "Calisthenics, which combines the concepts of beauty and strength, is a versatile workout method that uses your own body weight for resistance. It helps build strength, improve physique, and boost endurance, making it suitable for everyone—from beginners to advanced athletes. Each exercise has progressions to match your fitness level, and the satisfaction of mastering your own body weight will keep you motivated. You can practice calisthenics anywhere, whether at home, in the gym, or at the park, as it requires minimal to no equipment. Plus, there's something incredibly rewarding about showing off your handstands and pull-ups wherever you go. Start your journey with us and unlock superhuman strength.",
            "ctaButton": {
                "text": "Train With Us",
                "link": "/programs"
            },
            "image": {
                "url": "/images/homepage/cali-banner.png",
                "alt": "Our programs"
            },
            // "type": "mainSection"
        },
        {
            "title": "Why join us?",
            "cards": [{
                "title": "Expert Coaches",
                "description": "Learn from certified and experienced coaches to reach your goals.",
                "image": {
                    "url": "/images/homepage/expert.png",
                    "alt": "Coaches"
                },
            },
            {
                "title": "Workout from Anywhere",
                "description": "Don't have access to gym, don't have enough time, workout from literally anywhere with us",
                "image": {
                    "url": "/images/homepage/wfh.jpg",
                    "alt": "Workout From Anywhere"
                },
            },
            {
                "title": "Suitable for all Levels",
                "description": "There is no barrier to entry, completely beginner friendly",
                "image": {
                    "url": "/images/homepage/beginner.png",
                    "alt": "All Levels"
                }
            },
            {
                "title": "Reach your Goals",
                "description": "With the right progessions and programming reach your goals faster and smarter.",
                "image": {
                    "url": "/images/homepage/goals.png",
                    "alt": "Reach you Goals"
                }
            }
            ],
            "type": "cards"
        },
        {
            "title": "Explore Our Training Programs",
            "cards": [{
                "title": "Calisthenics Live Online Class",
                "description": "From group batches, to semi-private classes to personal training and a separate batch for skill training, we got it all",
                "image": {
                    "url": "/images/homepage/live.png",
                    "alt": "Training Programs"
                },
                "ctaButton": {
                    "text": "View Details",
                    "link": "/programs?tag=calisthenics-live"
                },
            },
            {
                "title": "Calisthenics Guided Programs",
                "description": "Get personalised and customised workouts according to your strength level and workout at your own time with continous guidance and form-check via videos",
                "image": {
                    "url": "/images/homepage/guided.png",
                    "alt": "Training Programs"
                },
                "ctaButton": {
                    "text": "View Details",
                    "link": "/programs?tag=calisthenics-guided"
                },
            },
            {
                "title": "Upcoming Workshop",
                "description": "Join our upcoming online and offline workshops to learn all about calisthenics",
                "image": {
                    "url": "/images/homepage/workshop.png",
                    "alt": "Training Programs"
                },
                "ctaButton": {
                    "text": "View Details",
                    "link": "/programs?tag=calisthenics-workshop"
                },
            }
            ],
            "type": "cards-flip"
        }, {
            "title": "Success Stories",
            "description": "See what our clients have to say",
            "ctaButton": {
                "text": "View more",
                "link": "https://www.google.com/maps/place/Grip+It+By+Srushti/@9.3741102,-74.9524576,2z/data=!4m2!3m1!1s0x0:0xeab077da95e6cf39?sa=X&ved=1t:2428&ictx=111"
            },
            "testimonials": [
                {
                    "user": {
                        "firstName": "Kavya",
                        "lastName": "Atmakuri",
                        "profileImage": "https://media.licdn.com/dms/image/D5603AQGJOiZy1u0H2w/profile-displayphoto-shrink_400_400/0/1713394409381?e=1727308800&v=beta&t=o7DtxDkj_No4z4eVFGZRPNcue_kmXggM6UEfNPBn6dw",
                        "designation": "Software Engineer"
                    },
                    "testimony": "Look at what our client Kavya Atmakuri says about us, she has been working out with us for past 3 years in the Online Live Class from unlocking her first Push Up, to now trying to master the handstands, her journey has been nothing but inspirational.",
                    "videoTestimony": {
                        "videoId": "ia2hT6S8RKA",
                        "alt": "hi my name is Kavya and I've been working out with gripit and Srushti for the last 3 years there's literally no barrier to entry with these classes, you don't need any equipment you just have to show up to the class and Srushti will find a way to make the workout happen and challenge you. There's a focus on getting your foundations right so like the basic movements like push-ups squats pull-ups but adding on to those we work on a lot of different things like different skills like handstands headstands elbow levers and then we try different workout forms like animal flow and we do a bit of cardio and there's a good range of variety which means that you'll never get bored the coaches at gripit are quite amazing they're very positive and encouraging and they do not give up on you so I personally it took me more than a year to be able to do my first kick up into a handstand and for me like for people are familiar with this it might sound very simple but for some reason I just could not get it and I felt like giving up so many times and I did not want to try it on many days but Srushti kept pushing me and I finally was able to do it and now I'm learning how to balance in my handstand."
                    },
                    "rating": 5
                },
                {
                    "user": {
                        "firstName": "Sreedevi",
                        "lastName": "",
                        "profileImage": "https://cdn.iconscout.com/icon/free/png-256/free-s-characters-character-alphabet-letter-36031.png?f=webp&w=256",
                        "designation": "Student"
                    },
                    "testimony": "Here is our client Sreedevi, who has been with us for 3 years now, she never missed to attend a session or workout no matter where ever she is travelling due to a work, even if it means working out from the hotel room or in outdoor or sitting at home. The online format and flexibility has been a boon for her fitness journey. Now, on the way to unlocking many new skills.",
                    "videoTestimony": {
                        "videoId": "3Qir5O3I2m0",
                        "alt": "hi, this this sreedevi and I've been working out with Srushti for the past 3 years now and it's going great. In the initial days I had my doubts on how would online training workout for me, how would I know if my form is correct, if I am doing it right, but Srushti makes sure to pay individual corrections and give  you individual feedback and corrections. so over a period of time I've noticed great improvement in my form and strength and endurance also.  One more thing that attracted me here was, you literally need no equipment, any requirement for the exercises is always found at home, be it a bag of weights or bottles or towels. So that has made it effortless and literally you have to just wake up and be there and workout. I am looking forward to more of these and continuing to challenge myself"
                    },
                    "rating": 5
                },
                {
                    "user": {
                        "firstName": "Dr Kailash",
                        "lastName": "Gadhvi",
                        "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjUVdz88fVJtXrCbeQ0F_bhCMzDqjQuz5JeIj5qVZzkHCqU0kRLV=w75-h75-p-rp-mo-br100",
                        "designation": "Gynac Laproscopy Surgeon"
                    },
                    "testimony": "I am Dr. Kailash gadhvi. Gynec laparoscopy surgeon. Just want to share that calisthenics is one of the best exercises and it's privilege to do it under guidance of master like Srushti. I was suffering  from trapezitis and shoulder pain. Since one year had consulted different physiotherapists, orthopedicians, Tool medicines, took diff diff hot nd cold nd ultrasonic therapies too but my pain had not relievd. And at one point I was thinking to leave laparoscopy...( bcz I. Laparoscopy ..while surgery Yr shoulder gets maximum strain..nd thats why frozen shoulder is common in lap surgeons..). I have joined this calisthenics program before 2 months..And under guidance nd direct supervision of srustimam , my shoulder pain has completely relieved, at a time I was not able to even sleep bcz of severe pain. My reflexes have become faster Body has got more flexibility and I feel energetic throughout the day. I m thankful to dr.preeti madam for suggesting me calisthenics and  thankful to srusti madam for her continuous support..best guidance nd direct supervision. Initially every  exercise steps were looking very easy...but they r highly ..highly effective...nd with almost zero risk to get injury. Thanks for spreading this wonderful  method of making body fit nd flexible.",
                    "rating": 5
                },
                {
                    "user": {
                        "firstName": "Khushi",
                        "lastName": "Choudary",
                        "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjXO1vzctjOJIF6bOFwFBwE8siqp7FwpsyRokR-vmFbLFHt72EaJrA=w75-h75-p-rp-mo-br100",
                        "designation": ""
                    },
                    "testimony": "When you know you don’t have a fixed schedule or are always travelling, Srushti’s class is a way you can ensure that you workout. No matter if you are a beginner or a pro, she can help you ace your workout goals. You are sure to see a result in your strength. I honestly enjoy her classes a lot. You won’t feel like you’re missing out on joining the gym because she will help you workout with things that you will have available at home. She will give you alternates for every equipment!Definitely a must recommend.",
                    "rating": 5
                },
            ],
            "type": "testimony"
        },
        ]
    },
    "contactUsPageData": {
        "title": "Contact Us",
        "description": "If you have any questions, please feel free to reach out to us via email or social media.",
    },
    "programsPageData": {
        "programsSection": {
            "title": "Programs",
            "description": "We offer a variety of programs to help you achieve your fitness goals.",
            "tags": [
                {
                    "title": "Calisthenics Programs",
                    "id" : "cali-all",
                },
               /* {
                    "title": "Online Live Cali Class",
                    "id": "calisthenics-live",
                },
                {
                    "title": "Guided Cali Program",
                    "id": "calisthenics-guided",
                },*/
                {
                    "title": "Offline Workshops",
                    "id": "calisthenics-workshop",
                },
                {
                    "title": "Yoga",
                    "id": "yoga",
                }
            ],
            "hideTagsFilter": false,
            "variantsConfiguration": [
                {
                    "key": "numberOfClasses",
                    "title": "Number of Classes",
                },
                {
                    "key": "duration",
                    "title": "Duration",
                },
                {
                    "key": "timings",
                    "title": "Timings in IST",
                }
            ],
            "programs": [
                {
                    "id": "dce27b74-f4ae-4441-a99d-80d8b203088e",
                    "displayId": "yoga",
                    "name": "Group Yoga Classes",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/yoga/yoga/group_yoga.jpg"
                    ],
                    "description": "We offer online and offline classes, group and personal training both. We specialise in therapy yoga for special patients having conditions like diabetes, dementia, physical disability,  Alzheimer's and more. We also offer pre-natal and post-natal yoga. Ms. Ekta Shah is certified in the following disciples:",
                    "descriptionSummary": "Let's connect to your inner self",
                    "summaryBulletPoints": [
                        "Live classes 3 days a week",
                        "Traditional Yoga",
                    ],
                    "bulletPoints": [
                        "Madhyama Yoga Siksha and Comprehensive Yoga Teacher Training Course 900 hours from The Yoga Institute Santacruz",
                        "Certification in Hath Yoga Shudhui Kriya- Asanas, Ambika Yog Kutir (2010)",
                        "Power yoga from Yog Vinyasa Institute",
                        "Diploma in Yogic Education",
                        "Yoga Teacher's Training Course , Yoga Vidya Niketan, Dadar",
                        "Diploma in Yogic Therapy, Natural Living and Naturopathy from YVN",
                        "Total teaching experience of 17+ years with over 10,000+ students"
                    ],
                    "tags": [
                        "yoga"
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Khushbu",
                                "lastName": "Karia",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjWqcBkxc9ArIgJHmPKwxG9cPjFib5yb9Q-4coSXFGKOBA=w75-h75-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "Ekatvam yoga classes are hands down the best yoga classes! The one thing I love about Ekta miss is her dedication and knowledge about yoga. She communicates so well and makes you feel very comfortable and an active part of it. She encourages all the students to do better and this has helped me a lot. I never imagined to be able to do what she helped me learn and practice. The one hour in a day I spent doing yoga with Ekta miss was the best hour of my day. I got everything I needed, a little bit of work-out to break some sweat, strong stretch in sore muscles and deep relaxation at the end. I always thought that my body is not made for yoga, too stiff, but Ekta miss patiently worked with me to help me relax and make sure I did the yoga poses correctly.  I’ve been practicing yoga with Ekta miss for a year now and I can definitely observe noticeable differences in my body, I gained so much flexibility and strength! I highly recommend ekatvam yoga classes to everyone , so that many people can benefit from it!",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Ruma",
                                "lastName": "",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocLmOgGk4b1b31yaUtGrcXvesp4Csc8JPF-WQmUpO2nn=w75-h75-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "Very professional and positive. Been training under her for months now and the change I feel in me is amazing. For youngsters, her modifications and varieties of asanas and exercises don’t let you feel bored, as per the common perception of yoga. She possess in-depth knowledge about yoga and guides very accurately and tenderly. Not just yoga, the classes are a mix and match of everything - asanas, meditation, cardio, intense exercises and relaxation. Her tricks and relaxing exercises help not just during the class but even in times of stress or insomnia. The best self-tuning and toning sessions, especially in such Covid scenario. Just a superb experience!",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": "Is this program suitable for beginners?",
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to yoga or looking to level up, our trainers will tailor the session to your specific needs."
                        },
                        {
                            "question": "Do I need any specific fitness equipment?",
                            "answer": "No, a yoga mat/towel is suffiecient."
                        },
                        {
                            "question": "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes, allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effectives experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/YTjeTtjTVjgfPSja6"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333q",
                            "displayId": "yoga-details-3-days-a-week-1-month-subscription",
                            "price": 1800,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/YTjeTtjTVjgfPSja6",
                                "timings": "6.30 am, 7.30 am",
                                "duration": "1 month",
                                "numberOfClasses": "3 days a week"
                            }
                        },
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333j",
                            "displayId": "yoga-details-3-days-a-week-3-months-subscription",
                            "price": 4800,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/YTjeTtjTVjgfPSja6",
                                "timings": "6.30 am, 7.30 am",
                                "duration": "3 months",
                                "numberOfClasses": "3 days a week"
                            }
                        }
                    ]
                },
                {
                    "id": "c527c8fa-6a08-4f6e-8ad2-56f74d1ca8ef",
                    "displayId": "meditation-and-pranayama",
                    "name": "Meditation and Pranayama",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/yoga/meditation-and-pranayam/pranayama.jpg"
                    ],
                    "description": "We believe in overall wellbeing, thus our mental health is equally important as our physical health. We have special Mediation and Pranayama batch under the expert guidance of Ms. Ekta Shah, with 17 years of profound experience in yoga therapy with teaching over 10000+ students, learn to calm the mind, reduce stress, and cultivate mindfulness. Our meditation sessions are designed to help you find inner peace, enhance mental clarity, and establish a deeper connection with yourself. Mrs. Ekta Shah is certified and holds the following accolades: ",
                    "descriptionSummary": "Get started with your fitness journey",
                    "summaryBulletPoints": [
                        "Connect with your inner self",
                        "Learn from anywhere under expert guidance",
                    ],
                    "bulletPoints": [
                        "Madhyama Yoga Siksha and Comprehensive Yoga Teacher Training Course 900 hours from The Yoga Institute Santacruz",
                        "Certification in Hath Yoga Shudhui Kriya- Asanas, Ambika Yog Kutir (2010)",
                        "Power yoga from Yog Vinyasa Institute",
                        "Diploma in Yogic Education",
                        "Yoga Teacher's Training Course , Yoga Vidya Niketan, Dadar",
                        "Diploma in Yogic Therapy, Natural Living and Naturopathy from YVN",
                        "Total teaching experience of 17+ years with over 10,000+ students"
                    ],
                    "tags": [
                        "yoga"
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Keval",
                                "lastName": "Veera",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjU4-cBPBXBicQAgX5sxIuLIDH-TIpb-Hre7kjptyuAqeeM=w75-h75-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "Excellent !! The Pranayama (Meditation) sessions by Ekta Mam are detail oriented, well guided and have a holistic approach. You learn something new in every single class and always leave feeling refreshed and renewed. Although the classes are in groups, Ekta Mam always gives personal attention to each & everyone present in the batch. This one on one interaction helps you practice the breathing techniques & exercises  perfectly and get the maximum out of each class. Ekta Mam has deep & extensive knowledge in yoga & pranayama and she generously shares her insights and personal experiences in a way which is very accessible and helpful. I absolutely recommend Ekta Mam's Pranayama sessions to anyone interested in practising meditation.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Rupali",
                                "lastName": "Kalav",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjX2hUzLjW4oxH1MJ0Bl9IpmE4hzaRyr86-z3zg0emWbESs=w75-h75-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "I started practising Yoga 3 years ago, a new journey began for me. The meditation, pranayama, really helped me to deal well with stress and daily challenges. I will credit Ekta Ma'am for generating the interest in me for Yoga. She is passionate, patient, addresses issues, and will ensure you thoroughly enjoy the Yoga sessions. I would strongly recommend her online classes.",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": "Is this program suitable for beginners?",
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to yoga or looking to level up, our trainers will tailor the session to your specific needs."
                        },
                        {
                            "question": "Do I need any specific fitness equipment?",
                            "answer": "No, a yoga mat/towel is suffiecient."
                        },
                        {
                            "question": "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes, allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effectives experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/YTjeTtjTVjgfPSja6"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333q",
                            "displayId": "yoga-details-3-days-a-week-1-month-subscription",
                            "price": 1800,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/YTjeTtjTVjgfPSja6",
                                "timings": "7.30 am",
                                "duration": "1 month",
                                "numberOfClasses": "3 days a week"
                            }
                        },
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333j",
                            "displayId": "yoga-details-3-days-a-week-3-months-subscription",
                            "price": 4800,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/YTjeTtjTVjgfPSja6",
                                "timings": "7.30 am",
                                "duration": "3 months",
                                "numberOfClasses": "3 days a week"
                            }
                        }
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a96j",
                    "displayId": "yoga-personal-training",
                    "name": "Semi-Personal or Personal Training",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/yoga/pt/pt_1.jpeg"
                    ],
                    "description": "We offer online and offline classes, group and personal training both. We specialise in therapy yoga for special patients having conditions like diabetes, dementia, physical disability,  Alzheimer's and more. We also offer pre-natal and post-natal yoga. Ms. Ekta Shah is certified in the following disciples:",
                    "descriptionSummary": "Get started with your fitness journey",
                    "summaryBulletPoints": [
                        "1-on-1 training over video call",
                        "Cater to special needs",
                    ],
                    "bulletPoints": [
                        "Madhyama Yoga Siksha and Comprehensive Yoga Teacher Training Course 900 hours from The Yoga Institute Santacruz",
                        "Certification in Hath Yoga Shudhui Kriya- Asanas, Ambika Yog Kutir (2010)",
                        "Power yoga from Yog Vinyasa Institute",
                        "Diploma in Yogic Education",
                        "Yoga Teacher's Training Course , Yoga Vidya Niketan, Dadar",
                        "Diploma in Yogic Therapy, Natural Living and Naturopathy from YVN",
                        "Total teaching experience of 17+ years with over 10,000+ students"
                    ],
                    "tags": [
                        "yoga"
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Gunvanti",
                                "lastName": "Gala",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocIMttAEyaDOuSVEgcHFDzvnOXTgsYoSNNk84mv3ReGf=w75-h75-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "I am Gunvanti Gala. I am 62 years old house wife staying in Matunga, Mumbai . Last few years have been very tough for me due to many complication in my health.  During lockdown due to the ongoing crisis in those days in world and due to all negative news coming from around the world i fell into depression and i was worried for the health of my family members  all the time .I lost my balance and fell in the house one  day and got multiple fractures in the body . Cracks in my body was so minor and in such places that doing plaster was not possible.after many many days  of treatment one fine day test showed that i had osteoporosis which made my bones very weak and delicate like a small baby. My shoulders became so weak that I could not pick up 1 kg weight in my hand .i had to face new problem after every few days .Then i met my saviour  Ekta shah. Ekta shah was very kind and patient.She started curing me step by step . She Decided to treat my Mental heath first and then physical health . And again my journey towards healthy life began.During one month of counselling i started gaining confidence slowly and withing few weeks i was all set to start my physical fitness.  Ekta started my strength training and her systematic exercise program helped me a lot . I had weak shoulder ,back and legs. Earlier i could not stand for more than 15 mins and now ii can walk for one hour . I dont need back support anymore while sitting on chair .i can lift my legs now. I can pick up heavy weights like before now.  I am very thankful to Ekta shah and i am blessed to hv her as my fitness coach.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Anand",
                                "lastName": "Mehta",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJ_Ghxb8RYY0Wx0bHejyXZ7eiJ7uFnAowpiiEnJ0vaR=w75-h75-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "I started my journey of yoga since last 10 years with Ekta, she's has given my body & mind a new beginning, her involvement in making me do aasanas, pranayam & other high intensity exercise is just outstanding, gradually all my family members joined her classes,  Guys believe me she's the best yoga teacher in town!!!!!",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": "Is this program suitable for beginners?",
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to yoga or looking to level up, our trainers will tailor the session to your specific needs."
                        },
                        {
                            "question": "Do I need any specific fitness equipment?",
                            "answer": "No, a yoga mat/towel is suffiecient."
                        },
                        {
                            "question": "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes, allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effectives experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "mailto:gripitbysrushti@gmail.com"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333t",
                            "displayId": "pt-details",
                            "noPriceDisplayText": "Request fees via email",
                            "price": "",
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "mailto:gripitbysrushti@gmail.com",
                                "noPriceDisplayTextLink" : "mailto:gripitbysrushti@gmail.com",
                            },
                        },
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a9pp",
                    "displayId": "personalised-program",
                    "name": "Personalised Workout Program",
                    "productType": "COURSE",
                    "programType": "personalisedProgram",
                    "images": [
                        "/images/programs/calisthenics/push-up/pushup_white.jpg"
                    ],
                    "description": '<h1>Why Settle for Generic When You Can Go Personalized?</h1><h2> Every body is unique, and so is every fitness journey. Progress, adaptability, and lifestyle vary from person to person—there’s no such thing as a one-size-fits-all solution! To help you achieve your goals faster and maximize your time and effort, we’ve designed a truly personalized program tailored to your specific needs, aspirations, lifestyle, and schedule. <h2> ',
                    "descriptionSummary": "Unlock your fitness goal with cutsomised and personalised programs",
                    "summaryBulletPoints": [
                        "Train specifically towards your goals",
                        "Customised plans for muscle ups, front lever, full body workouts"
                    ],
                    "bulletPoints": [
                        "One 30min 1-on-1 Online Session to assess your current strength level",
                        "15 workouts tailored to your strength level with regular check-ins",
                        "Our commitment extends to providing continuous online guidance, including form checks through submitted videos",
                        "Increase body strength and endurance",
                        "Workout at any time at your own pace"
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-guided",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Abhiruchi",
                                "lastName": "",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjVu2MniIQg7IT6XgR8DpA-WSr4oIvxf-BrfDqvXj68F7sc=w120-h120-p-rp-mo-br100",
                                "designation": "Software Engineer"
                            },
                            "testimony": "I enrolled for the push up program with Srushti and by the end of the dedicated  12 workouts I couldn’t believe how the program was so strategically and dynamically structured that I could actually hit a full push up which had been my dream for a long long time. Srushti was patiently available to guide every step of the way and taught me the sheer power of consistency with her fundamentals-focused workouts. I am beyond impressed and grateful. This is the best program to learn these crucial basics and I applaud Srushti for her crazy and unwavering dedication towards her students. Thanks a ton.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Apoorva",
                                "lastName": "Jade",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJAvDiCY-esnrSMHJF-HWhWpGL85KAbnGt6yUj-U-vokg0=w75-h75-p-rp-mo-br100",
                                "designation": "Content writer"
                            },
                            "testimony": "I've been training with Srushti for three months now, and I am genuinely in love with her classes and eagerly look forward to them each day. I used to find it difficult to complete even one inclined push-up, but with Srushti's support & guidance, I have not only unlocked my first push-up but can also do up to five floor push-ups. Her workouts are planned in a way that our entire body is worked out through the week. I am myself surprised with the strength and endurance I have developed. Extremely grateful for the constant support & motivation she has given me! ",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": 'Will I be able to do a Push Up after 10 workouts? ',
                            "answer": 'You may or may not achieve your Push Up Up within 10 workouts or any given timeframe. It completely depends on your current strength level, consistency, and body adaptability. But you will definitely improve and progress compared to when you started.'
                        },
                        {
                            "question": 'How long does the program take?',
                            "answer": "The ideal timeframe to complete 15 workouts is 5 weeks (3 workouts per week), but we extend the validity of programs up to 8 weeks/2 months. After which you need to renew your subscription if you left it halfway for any reason."
                        },
                        {
                            "question": 'What happens after 10 workouts? ',
                            "answer": "You can renew your subscription for another 10 workouts for Rs. 1500 or choose another program alongside. It does not stop at your first push up, let's hit more reps and variations."
                        },
                        {
                            "question": 'Can I do other programs alongside this? ',
                            "answer": "Yes, since these are customized programs. It will be designed taking care of all your current activity and other goals."
                        },
                        {
                            "question": 'Is this program suitable for beginners? ',
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": 'Do I need any specific fitness equipment?',
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question":
                                "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://docs.google.com/forms/d/e/1FAIpQLScgcbTFy6xeQ_TuRhELNfMHQ_yrb5uQv_iKBArwsvz3yFoGsw/viewform?usp=sf_link"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f333kk",
                            "displayId": "personalised-program",
                            "discount": 0,
                            "noPriceDisplayText": "Starting ₹3499",

                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://docs.google.com/forms/d/e/1FAIpQLScgcbTFy6xeQ_TuRhELNfMHQ_yrb5uQv_iKBArwsvz3yFoGsw/viewform?usp=sf_link",
                                "noPriceDisplayTextLink": "",

                            }
                        },
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a96m",
                    "displayId": "fbw",
                    "name": "Calisthenics Online Classes",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/calisthenics/fbw/fbw_white.jpg"
                    ],
                    "description": "Our program is designed for individuals who want to work on full body strengthening which includes push, pull, core, legs and added bonus as skills like headstand, handstand, elbow lever as well. If you are someone who is looking to get started with fitness and doesn't have a gym nearby nor equipments at home, or your work schedule doesn't allow you to go to gym, this program is perfect for you.",
                    "descriptionSummary": "Build strength with Live Online Calisthenics Classes, open to all levels with workout from home.",
                    "summaryBulletPoints": [
                        "Live classes 3-5 days a week",
                        "Begineer to Intermediate level",
                    ],
                    "bulletPoints": [
                        "Quality Teaching under certified professionals with proper feedback on form",
                        "Workout from home with no or minimal equipments like chair, towel, bottles.",
                        "Caters to complete beginner to intermediate level",
                        "Convenient batch timings of 6am, 7am, 8am, 7pm, 8pm with 3-5 classes per week",
                        "Bonus: Learn cool skills like handstand, levers along with full body strengthening",
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-live",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Devanshi",
                                "lastName": "Shah",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJ6UdQK2EwqFM6XjDuiCPFSUN7gbI6jI9v06DyS3ynO8VM6Bg=w75-h75-p-rp-mo-br100",
                                "designation": "Software Engineer"
                            },
                            "testimony": "I saw her push-up workshop poster in July 2020 and thought of giving it a try since there was no physical activity in lockdown. I'm so glad I decided to go for it. There was no looking back after that. I never knew online classes could be so much fun. The kind of efforts that Srushti puts in, the personalized feedback and workout plans you get, compensation in case any sessions are missed from her side, the discipline and the way she overdelivers every time is incomparable!    There are always new ways of making workout fun and Srushti has never failed to inspire me is something very hard to find. More than a year of joining Grip It and working out and I've seen myself grow. Would definitely recommend it to everyone who want to include fitness in their lifestyle.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Urvi",
                                "lastName": "Chheda",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjViiLYs1EmGEoF5iLIuGNnYKnlKxZJa2IhTZc6pDD-I09s=w75-h75-p-rp-mo-br100",
                                "designation": "Artist"
                            },
                            "testimony": "Grip it by Srushti is a one stop for all those who have been meaning to start working out or wish to structure it with an objective benefit. Srushti may be young as compared to others in the field but within more than a year of experience in instructing she has grasped it super well, the self experience is a proof to the same. I can do more than 30 push-ups in one go from a freaking zero! Her passion, discipline and determination is contagious, which will infect you in no time. Go ahead get in touch and let the fitness reveal its magic to you!  Hold it forever😃 best wishes to this girl!",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": "Is this program suitable for beginners?",
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": "Do I need any specific fitness equipment?",
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question": "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "How many people per batch?",
                            "answer": "To maintain quality and personal attention, we stick to 10-15 people per batch depending."
                        },
                        {
                            "question": "Incase you miss a class, what happens?",
                            "answer": "Incase you are missing the class due to travelling, not well or other reasons, you attend the other batch (of respective 3/5 day) on the same day with prior notice given to the trainer once in a while, but there cannot be cover up on the other days, it is considered as a monthly batch and not session-wise tracking to maintain the upper limit of batch. No recording is provided, however the routine will be provided incase you want to workout on your own."
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/s3eKPSjV6UG25ZDY8"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333q",
                            "displayId": "fbw-5-days-1-month",
                            "price": 3000,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/s3eKPSjV6UG25ZDY8",
                                "timings": "6am, 7am, 7pm",
                                "duration": "1 month",
                                "numberOfClasses": "5 days a week"
                            }
                        },
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333t",
                            "displayId": "fbw-3-days-1-month",
                            "price": 1800,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/s3eKPSjV6UG25ZDY8",
                                "timings": "6am, 7am, 7pm, 8am, 8pm",
                                "duration": "1 month",
                                "numberOfClasses": "3 days a week"
                            }
                        },
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333j",
                            "displayId": "fbw-3-days-3-months",
                            "price": 4500,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/s3eKPSjV6UG25ZDY8",
                                "timings": "6am, 7am, 7pm, 8am, 8pm",
                                "duration": "3 months",
                                "numberOfClasses": "3 days a week"
                            }
                        },
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333o",
                            "displayId": "fbw-5-days-3-months",
                            "price": 7500,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/s3eKPSjV6UG25ZDY8",
                                "timings": "6am, 7am, 7pm",
                                "duration": "3 months",
                                "numberOfClasses": "5 days a week"
                            }
                        },

                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a96k",
                    "displayId": "skill",
                    "name": "Live Skill Batch",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/calisthenics/skill/skill_2_white.jpg"
                    ],
                    "description": "This program caters to those who wanted to learn skills like handstand, headstand, elbow lever, back lever, front lever, or have any specific goal like Pull-Up and more, and want personalised touch along with Live Classes in a group.",
                    "descriptionSummary": "Let's unlock some cool skills with us like arm balacing, pull ups, levers",
                    "summaryBulletPoints": [
                        "Live classes 2 days a week",
                        "Arm balancing skills, Pull Ups, Levers",
                    ],
                    "bulletPoints": [
                        "Quality Teaching under certified professionals with proper feedback on form",
                        "Caters to complete beginner to intermediate level",
                        "Tuesday and Thursday 8:30am-9:30am every week",
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-live",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Yogesh",
                                "lastName": "D",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocLR0NN1MCfUHjojDC3GPt530CsuzhAPHonlQ4638zIIJVI=w75-h75-p-rp-mo-br100",
                                "designation": "Software Engineer"
                            },
                            "testimony": "Would definitely recommend to any beginner/intermediate looking for balanced development of their strength, and also to learn some calisthenics skills. I started going to the gym to lose weight but realised that I lacked core strength and flexibility to properly do some of the exercises. I quit gym and joined Srushti's workshops and after 2.5 months of training I saw balanced development of my strength, something which I was unable to achieve in the gym by myself. I learnt how to do pushups with proper form, got a better sense of muscle-mind coordination and can do a headstand and hold the bakasana pose for a few seconds while being overweight, and can do weighted squats in the gym now -- something I wasn't able to do before because I lacked the strength and flexibility it requires. The fees are reasonable and cheaper than personal training at your average gym.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Apoorva",
                                "lastName": "Jade",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJAvDiCY-esnrSMHJF-HWhWpGL85KAbnGt6yUj-U-vokg0=w75-h75-p-rp-mo-br100",
                                "designation": "Content writer"
                            },
                            "testimony": "I've been training with Srushti for three months now, and I am genuinely in love with her classes and eagerly look forward to them each day. I used to find it difficult to complete even one inclined push-up, but with Srushti's support & guidance, I have not only unlocked my first push-up but can also do up to five floor push-ups. Her workouts are planned in a way that our entire body is worked out through the week. I am myself surprised with the strength and endurance I have developed. Extremely grateful for the constant support & motivation she has given me! ",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": "Is this program suitable for beginners?",
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": "Do I need any specific fitness equipment?",
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question": "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": " per month",
                        "registrationLink": "https://forms.gle/pgL9NhujS5fPysuy7"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333y",
                            "displayId": "skill-details",
                            "price": "2400",
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": " per month",
                                "registrationLink": "https://forms.gle/pgL9NhujS5fPysuy7"
                            },
                        },
                    ]
                },
                {
                    "id": "9bcb7c59-987e-4f82-90d8-376d20f2601b",
                    "displayId": "pullup",
                    "name": "Guided Pull Up Program",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/calisthenics/pull-up/pullup_white.jpg"
                    ],
                    "description": "Our program is designed for individuals facing challenges in advancing their pull-up skills, whether it is getting your first pull up or increasing the number of reps and learning variations. It offers a user-friendly experience with comprehensive support and accountability checks led by our expert trainers. Join us to break through barriers in your pull-up journey with a program that's personalized, accessible, and supported by our dedicated trainers.",
                    "descriptionSummary": "Unlock your 1st Pull Up with Clean form with personalised program",
                    "summaryBulletPoints": [
                        "Achieve your first Pull Up",
                        "Beginner-friendly with complete guidance throughout"
                    ],
                    "bulletPoints": [
                        "One 30min 1-on-1 Online Session to assess your current strength level",
                        "15 workouts (3 per week) tailored to your strength level with regular check-ins",
                        "Our commitment extends to providing continuous online guidance, including form checks through submitted videos",
                        "Increase upper body strength and endurance",
                        "Workout at any time at your own pace"
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-guided",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Vrushali",
                                "lastName": "Prasade",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjVkX0vaAVGRO-y3pBYkaWuZeiJ4kr-muhp_MYzW2Dt8_GI=w75-h75-p-rp-mo-br100",
                                "designation": "Software Engineer"
                            },
                            "testimony": "I've been training with Srushti and doing the pull up program with her. She's very methodical and quite strict when it comes to phasing the workouts in and not overworking yourself. She pays attention to all the workouts I do, goes through the videos I submit, and provides detailed feedback on the form so as to ensure we're not doing any of the recommended workouts wrongly. Also, she follows up and makes sure we're being regular in doing the workouts and that has really helped me in not slacking off during the pull up program.      It's been a great experience training under her and I intend to continue to do so for the foreseeable future!",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Vivek",
                                "lastName": "Narayanan",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjUNy0WL4Zh_HY4RGTe4TDmv8REBy_SIn1dSJ6w6j8Q3J-Vs=w75-h75-p-rp-mo-ba2-br100",
                                "designation": "Student"
                            },
                            "testimony": "Excellent mentor. I joined Srusthi's pull up program  She teaches these little techniques that improves the workout. Despite being overweight, i managed to build upper body strength - thanks to her",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": 'Will I be able to do a Pull Up after 15 workouts?',
                            "answer": 'You may or may not achieve your Pull Up within 15 workouts or any given timeframe. It completely depends on your current strength level, consistency, and body adaptability. But you will definitely improve and progress compared to when you started.'
                        },
                        {
                            "question": 'How long does the program take?',
                            "answer": "The ideal timeframe to complete 15 workouts is 5 weeks (3 workouts per week), but we extend the validity of programs up to 8 weeks/2 months. After which you need to renew your subscription if you left it halfway for any reason."
                        },
                        {
                            "question": 'What happens after 15 workouts? ',
                            "answer": "You can renew your subscription for another 15 workouts for Rs. 1500 or choose another program alongside. It does not stop at your first pull up, let's hit more reps and variations."
                        },
                        {
                            "question": 'Can I do other programs alongside this? ',
                            "answer": "Yes, since these are customized programs. It will be designed taking care of all your current activity and other goals."
                        },
                        {
                            "question": 'Is this program suitable for beginners? ',
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to pull-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": 'Do I need any specific fitness equipment besides a Pull-Up Bar?',
                            "answer": "No, the primary requirement is a Pull-Up Bar (door pull-up bar or fixed). However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question":
                                "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/B7m5SpVvsERrb6qU9"
                    },
                    "variants": [
                        {
                            "id": "9b92d5b0-dd17-4c96-96a2-50d4251f64a3",
                            "displayId": "pullup-details",
                            "price": 2000,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/B7m5SpVvsERrb6qU9"
                            },
                        }
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a96a",
                    "displayId": "pushup",
                    "name": "Guided Push Up Program",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/calisthenics/push-up/pushup_white.jpg"
                    ],
                    "description": "Our program is designed for individuals facing challenges in advancing their push-up be it unlocking your first Push up, increasing the reps or learning new variations like Pike Push Up, explosive Push Ups and more. Join us to break through barriers in your push-up journey with a program that's personalized, accessible, and supported by our dedicated trainers.",
                    "descriptionSummary": "Unlock your 1st Push Up with Clean form with personalised program",
                    "summaryBulletPoints": [
                        "Achieve your first Push Up",
                        "Increase reps and learn variations"
                    ],
                    "bulletPoints": [
                        "One 30min 1-on-1 Online Session to assess your current strength level",
                        "10 workouts tailored to your strength level with regular check-ins",
                        "Our commitment extends to providing continuous online guidance, including form checks through submitted videos",
                        "Increase upper body strength and endurance",
                        "Workout at any time at your own pace"
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-guided",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Abhiruchi",
                                "lastName": "",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjVu2MniIQg7IT6XgR8DpA-WSr4oIvxf-BrfDqvXj68F7sc=w120-h120-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "I enrolled for the push up program with Srushti and by the end of the dedicated  12 workouts I couldn’t believe how the program was so strategically and dynamically structured that I could actually hit a full push up which had been my dream for a long long time. Srushti was patiently available to guide every step of the way and taught me the sheer power of consistency with her fundamentals-focused workouts. I am beyond impressed and grateful. This is the best program to learn these crucial basics and I applaud Srushti for her crazy and unwavering dedication towards her students. Thanks a ton.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Apoorva",
                                "lastName": "Jade",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJAvDiCY-esnrSMHJF-HWhWpGL85KAbnGt6yUj-U-vokg0=w75-h75-p-rp-mo-br100",
                                "designation": "Content writer"
                            },
                            "testimony": "I've been training with Srushti for three months now, and I am genuinely in love with her classes and eagerly look forward to them each day. I used to find it difficult to complete even one inclined push-up, but with Srushti's support & guidance, I have not only unlocked my first push-up but can also do up to five floor push-ups. Her workouts are planned in a way that our entire body is worked out through the week. I am myself surprised with the strength and endurance I have developed. Extremely grateful for the constant support & motivation she has given me! ",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": 'Will I be able to do a Push Up after 10 workouts? ',
                            "answer": 'You may or may not achieve your Push Up Up within 10 workouts or any given timeframe. It completely depends on your current strength level, consistency, and body adaptability. But you will definitely improve and progress compared to when you started.'
                        },
                        {
                            "question": 'How long does the program take?',
                            "answer": "The ideal timeframe to complete 10 workouts is 5 weeks (3 workouts per week), but we extend the validity of programs up to 8 weeks/2 months. After which you need to renew your subscription if you left it halfway for any reason."
                        },
                        {
                            "question": 'What happens after 10 workouts? ',
                            "answer": "You can renew your subscription for another 10 workouts for Rs. 1500 or choose another program alongside. It does not stop at your first push up, let's hit more reps and variations."
                        },
                        {
                            "question": 'Can I do other programs alongside this? ',
                            "answer": "Yes, since these are customized programs. It will be designed taking care of all your current activity and other goals."
                        },
                        {
                            "question": 'Is this program suitable for beginners? ',
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": 'Do I need any specific fitness equipment?',
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question":
                                "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/4PxUNpiXbNefG88i8"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333e",
                            "displayId": "pushup-details",
                            "price": 1500,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/4PxUNpiXbNefG88i8"
                            }
                        },
                    ]
                },
                /*{
                    "id": "0f20db85-6ad3-400e-a056-bae93047a96b",
                    "displayId": "goal-based",
                    "name": "Goal Based Training Program",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/calisthenics/push-up/pushup_white.jpg"
                    ],
                    "description": "Our program is designed for individuals facing challenges in advancing in their goals or aspiring to unlock new goals. It can be unlocking your first push up, pull up, muscle up, front lever, skills, variations of these or any other fitness goal you can think of. Join us to break through barriers in your fitness journey with a program that's personalized, accessible, and supported by our dedicated trainers.",
                    "descriptionSummary": "Unlock your fitness goal with cutsomised and personalised programs",
                    "summaryBulletPoints": [
                        "Train specifically towards your goals",
                        "Customised plans for muscle ups, front lever, full body workouts"
                    ],
                    "bulletPoints": [
                        "One 30min 1-on-1 Online Session to assess your current strength level",
                        "15 workouts tailored to your strength level with regular check-ins",
                        "Our commitment extends to providing continuous online guidance, including form checks through submitted videos",
                        "Increase body strength and endurance",
                        "Workout at any time at your own pace"
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-guided",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Abhiruchi",
                                "lastName": "",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjVu2MniIQg7IT6XgR8DpA-WSr4oIvxf-BrfDqvXj68F7sc=w120-h120-p-rp-mo-br100",
                                "designation": "Software Engineer"
                            },
                            "testimony": "I enrolled for the push up program with Srushti and by the end of the dedicated  12 workouts I couldn’t believe how the program was so strategically and dynamically structured that I could actually hit a full push up which had been my dream for a long long time. Srushti was patiently available to guide every step of the way and taught me the sheer power of consistency with her fundamentals-focused workouts. I am beyond impressed and grateful. This is the best program to learn these crucial basics and I applaud Srushti for her crazy and unwavering dedication towards her students. Thanks a ton.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Apoorva",
                                "lastName": "Jade",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJAvDiCY-esnrSMHJF-HWhWpGL85KAbnGt6yUj-U-vokg0=w75-h75-p-rp-mo-br100",
                                "designation": "Content writer"
                            },
                            "testimony": "I've been training with Srushti for three months now, and I am genuinely in love with her classes and eagerly look forward to them each day. I used to find it difficult to complete even one inclined push-up, but with Srushti's support & guidance, I have not only unlocked my first push-up but can also do up to five floor push-ups. Her workouts are planned in a way that our entire body is worked out through the week. I am myself surprised with the strength and endurance I have developed. Extremely grateful for the constant support & motivation she has given me! ",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": 'Will I be able to do a Push Up after 10 workouts? ',
                            "answer": 'You may or may not achieve your Push Up Up within 10 workouts or any given timeframe. It completely depends on your current strength level, consistency, and body adaptability. But you will definitely improve and progress compared to when you started.'
                        },
                        {
                            "question": 'How long does the program take?',
                            "answer": "The ideal timeframe to complete 15 workouts is 5 weeks (3 workouts per week), but we extend the validity of programs up to 8 weeks/2 months. After which you need to renew your subscription if you left it halfway for any reason."
                        },
                        {
                            "question": 'What happens after 10 workouts? ',
                            "answer": "You can renew your subscription for another 10 workouts for Rs. 1500 or choose another program alongside. It does not stop at your first push up, let's hit more reps and variations."
                        },
                        {
                            "question": 'Can I do other programs alongside this? ',
                            "answer": "Yes, since these are customized programs. It will be designed taking care of all your current activity and other goals."
                        },
                        {
                            "question": 'Is this program suitable for beginners? ',
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": 'Do I need any specific fitness equipment?',
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question":
                                "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://docs.google.com/forms/d/e/1FAIpQLScgcbTFy6xeQ_TuRhELNfMHQ_yrb5uQv_iKBArwsvz3yFoGsw/viewform?usp=sf_link"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333k",
                            "displayId": "goal-based-details",
                            "price": 2500,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://docs.google.com/forms/d/e/1FAIpQLScgcbTFy6xeQ_TuRhELNfMHQ_yrb5uQv_iKBArwsvz3yFoGsw/viewform?usp=sf_link"
                            }
                        },
                    ]
                },*/

                {
                    "id": "0f20db85-6ad3-400e-a056-bae9304pa96k",
                    "displayId": "pistol-squat",
                    "name": "Guided Pistol Squat Program",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/calisthenics/pistol-squat/pistol_squat_white.jpg"
                    ],
                    "description": "Our program is designed for individuals facing challenges in mastering the pistol squat, a movement that requires both strength and mobility. Whether you're aiming to achieve your first clean pistol squat or looking to increase your reps, we offers comprehensive support and accountability checks led by our expert trainers. Join us to break through barriers in your pistol squat journey with a program that's personalized, accessible, and supported by our dedicated trainers.",
                    "descriptionSummary": "Unlock your first pistol squat",
                    "summaryBulletPoints": [
                        "Customised plans for advancing in your pistol squat",
                        "Master strength and mobility"
                    ],
                    "bulletPoints": [
                        "One 20min 1-on-1 Online Session to assess your current strength level",
                        "12 workouts tailored to your strength level with regular check-ins",
                        "Our commitment extends to providing continuous online guidance, including form checks through submitted videos",
                        "Workout at any time at your own pace"
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-guided",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Abhiruchi",
                                "lastName": "",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjVu2MniIQg7IT6XgR8DpA-WSr4oIvxf-BrfDqvXj68F7sc=w120-h120-p-rp-mo-br100",
                                "designation": "Software Engineer"
                            },
                            "testimony": "I enrolled for the push up program with Srushti and by the end of the dedicated  12 workouts I couldn’t believe how the program was so strategically and dynamically structured that I could actually hit a full push up which had been my dream for a long long time. Srushti was patiently available to guide every step of the way and taught me the sheer power of consistency with her fundamentals-focused workouts. I am beyond impressed and grateful. This is the best program to learn these crucial basics and I applaud Srushti for her crazy and unwavering dedication towards her students. Thanks a ton.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Apoorva",
                                "lastName": "Jade",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJAvDiCY-esnrSMHJF-HWhWpGL85KAbnGt6yUj-U-vokg0=w75-h75-p-rp-mo-br100",
                                "designation": "Content writer"
                            },
                            "testimony": "I've been training with Srushti for three months now, and I am genuinely in love with her classes and eagerly look forward to them each day. I used to find it difficult to complete even one inclined push-up, but with Srushti's support & guidance, I have not only unlocked my first push-up but can also do up to five floor push-ups. Her workouts are planned in a way that our entire body is worked out through the week. I am myself surprised with the strength and endurance I have developed. Extremely grateful for the constant support & motivation she has given me! ",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": 'Will I be able to do a Pistol Squat after 10 workouts? ',
                            "answer": 'You may or may not achieve your Pistol Squat within 10 workouts or any given timeframe. It completely depends on your current strength level, consistency, and body adaptability. But you will definitely improve and progress compared to when you started.'
                        },
                        {
                            "question": 'How long does the program take?',
                            "answer": "The ideal timeframe to complete 15 workouts is 5 weeks (3 workouts per week), but we extend the validity of programs up to 8 weeks/2 months. After which you need to renew your subscription if you left it halfway for any reason."
                        },
                        {
                            "question": 'What happens after 10 workouts? ',
                            "answer": "You can renew your subscription for another 10 workouts for Rs. 1500 or choose another program alongside. It does not stop at your first Pistol Squat, let's hit more reps and variations."
                        },
                        {
                            "question": 'Can I do other programs alongside this? ',
                            "answer": "Yes, since these are customized programs. It will be designed taking care of all your current activity and other goals."
                        },
                        {
                            "question": 'Is this program suitable for beginners? ',
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": 'Do I need any specific fitness equipment?',
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question":
                                "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/7esjjgHMoic3uqM3A"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8fm33jk",
                            "displayId": "pistol-squat-details",
                            "price": 1500,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "https://forms.gle/7esjjgHMoic3uqM3A"
                            }
                        },
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a96j",
                    "displayId": "personal-training",
                    "name": "Semi-Personal or Personal Training",
                    "productType": "COURSE",
                    "images": [
                        "/images/programs/calisthenics/pt/pt_white.jpg"
                    ],
                    "description": "This is for individuals who want to train 1-on-1 over video call with one of our trainers, to reach their goals. Semi-personal refers to a small group of 2 to 5 individuals. Please mail us for more details on this.",
                    "descriptionSummary": "This is for individuals who want to train 1-on-1 over video call with one of our trainers",
                    "summaryBulletPoints": [
                        "1-on-1 training over video call",
                        "Acheive your goals with dedicated trainer",
                    ],
                    "bulletPoints": [
                        "Learn cool skills like handstand, levers, headstand",
                        "Quality Teaching under certified professionals with proper feedback on form",
                        "Workout from home virtually",
                        "Timings would be decided as suitable to both",
                    ],
                    "tags": [
                        "cali-all" //,"calisthenics-guided",
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Unnati",
                                "lastName": "Kumar",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocImlIQcnKnQcW4rGMJvilJMLOXKVqpeW7N9r5wUq2ECmqO6RA=w75-h75-p-rp-mo-br100",
                                "designation": "Doctor"
                            },
                            "testimony": "Ssrushti has been an amazing instructor and quite inspiring to learn from! I've attended her group sessions as well as personal training sessions. She has done a tremendous job in understanding my goals and current fitness level to tailor the workouts to my needs! Looking forward to reaching more of my fitness goals with her!",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Pranay",
                                "lastName": "",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjWWCBtO40Y4MpUOvfAspRWr9FuYVBZDjySydMimslyS7Hasl4Op=w75-h75-p-rp-mo-ba2-br100",
                                "designation": " "
                            },
                            "testimony": "I never had a training before tried a personal training, the trainer was really flexible, with great knowledge, suits accordingly moreover online, WORTH TO GIVE A TRY!!",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": "Is this program suitable for beginners?",
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": "Do I need any specific fitness equipment?",
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },
                        {
                            "question": "Are the workouts time-consuming?",
                            "answer": "Our workouts are designed to be efficient and effective. On average, each session takes about 45-60 minutes (including warm up and cool down), allowing you to integrate them into your daily routine without disrupting your schedule.",
                        },
                        {
                            "question": "What if I have a pre-existing injury or health condition?",
                            "answer": "Safety is our priority. Before starting the program, our trainers will inquire about any pre-existing injuries or health conditions. We may request additional information or recommend modifications to ensure a safe and effective workout experience.",
                        },
                        {
                            "question": "Is there a money-back guarantee if I'm not satisfied with the program or any refund?",
                            "answer": "No, once you enrol into the program there is no refund/cancellation or transferable option available, we believe in the efficiency and time and efforts our trainers put in",
                        },
                        // Add more FAQs as needed
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "mailto:gripitbysrushti@gmail.com"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333t",
                            "displayId": "pt-details",
                            "noPriceDisplayText": "Request fees via email",
                            "price": "",
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "registrationLink": "mailto:gripitbysrushti@gmail.com",
                                "noPriceDisplayTextLink" : "mailto:gripitbysrushti@gmail.com"
                            },
                        },
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a96a",
                    "displayId": "pushup-workshop",
                    "name": "Online Push Up Program",
                    "productType": "WORKSHOP",
                    "isArchived" : true,
                    "images": [
                        "/images/programs/calisthenics/push-up/pushupworkshop.jpeg"
                    ],
                    "description": "Our program is designed for individuals facing challenges in advancing their push-up be it unlocking your first Push up, increasing the reps or learning new variations like Pike Push Up, explosive Push Ups and more. Join us to break through barriers in your push-up journey with a program that's personalized, accessible, and supported by our dedicated trainers.",
                    "descriptionSummary": "Unlock your 1st Push Up with Clean form",
                    "summaryBulletPoints": [
                        "Achieve your first Push Up",
                        "Increase reps and learn variations"
                    ],
                    "bulletPoints": [
                        "Master Perfect Push Up Form",
                        "Learn Best Progressions to reach your first Push Up",
                        "Program your workouts effectively",
                        "Strengthen your muscles for Push Ups",
                        "Beginner-friendly, no equipment required",
                    ],
                    "tags": [
                        "calisthenics-workshop"
                    ],
                    "testimonials": [
                        {
                            "user": {
                                "firstName": "Abhiruchi",
                                "lastName": "",
                                "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjVu2MniIQg7IT6XgR8DpA-WSr4oIvxf-BrfDqvXj68F7sc=w120-h120-p-rp-mo-br100",
                                "designation": ""
                            },
                            "testimony": "I enrolled for the push up program with Srushti and by the end of the dedicated  12 workouts I couldn’t believe how the program was so strategically and dynamically structured that I could actually hit a full push up which had been my dream for a long long time. Srushti was patiently available to guide every step of the way and taught me the sheer power of consistency with her fundamentals-focused workouts. I am beyond impressed and grateful. This is the best program to learn these crucial basics and I applaud Srushti for her crazy and unwavering dedication towards her students. Thanks a ton.",
                            "rating": 5
                        },
                        {
                            "user": {
                                "firstName": "Apoorva",
                                "lastName": "Jade",
                                "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJAvDiCY-esnrSMHJF-HWhWpGL85KAbnGt6yUj-U-vokg0=w75-h75-p-rp-mo-br100",
                                "designation": "Content writer"
                            },
                            "testimony": "I've been training with Srushti for three months now, and I am genuinely in love with her classes and eagerly look forward to them each day. I used to find it difficult to complete even one inclined push-up, but with Srushti's support & guidance, I have not only unlocked my first push-up but can also do up to five floor push-ups. Her workouts are planned in a way that our entire body is worked out through the week. I am myself surprised with the strength and endurance I have developed. Extremely grateful for the constant support & motivation she has given me! ",
                            "rating": 5
                        }
                    ],
                    "faqs": [
                        {
                            "question": 'Will I be able to do a Push Up? ',
                            "answer": 'You may or may not achieve your Push Up Up within 2 days any given timeframe. It completely depends on your current strength level, consistency, and body adaptability. But you will definitely improve and progress compared to when you started.'
                        },

                        {
                            "question": 'Is this program suitable for beginners? ',
                            "answer": "Yes, our program is beginner-friendly, and it's designed to guide individuals at all fitness levels. Whether you're new to push-ups or looking to increase your reps, our trainers will tailor the workouts to your specific needs."
                        },
                        {
                            "question": 'Do I need any specific fitness equipment?',
                            "answer": "No, we use chair, bottles or bag which are easily available at home. However, if you have additional equipment or prefer specific accessories, our trainers can incorporate them into your personalized program."
                        },

                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/mBndFgqKmkMMvQXn9"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-85e8f8f3333e",
                            "displayId": "pushup-details",
                            "price": 199,
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "timings": "7:30am to 9am",
                                "duration": "3rd & 4th Aug'24",
                                "numberOfClasses": "2"
                            }
                        },
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a44a",
                    "displayId": "cali-retreat-nov24",
                    "name": "Cali Escape Retreat",
                    "productType": "WORKSHOP",
                    "isArchived" : true,
                    "images": [
                        "/images/programs/calisthenics/retreat/cali-retreat.png"
                    ],
                    "description": "Move out of the reel world and create real connections. Step out of your comfort zone into an enriching experience in the middle of an untouched natural environment along with practicing Calisthenics, Yoga and Swimmingwith our expert trainers",
                    "descriptionSummary": "Let's disconnect from reel world, to connect in real world.",
                    "summaryBulletPoints": [
                        "Learn Calisthenics, Yoga & Swimming",
                        "Live in an untouched environment"
                    ],
                    "bulletPoints": [
                        "Venue: Varanashi Organic Farms, Adyanadka, Karnataka (near Mangalore)",
                        "3 days or 5 days option",
                        "Open to All Genders & Age groups",
                        "Open to all levels: Beginner or Intermediate or Advance",

                    ],
                    "tags": [
                        "calisthenics-workshop"
                    ],
                    "testimonials": [
                        
                    ],
                    "faqs": [
                        
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/nm8XUF7rLMVtHF3z5"
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-bae93047a44b",
                            "displayId": "cali-retreat-nov24",
                            "noPriceDisplayText": "",
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "timings": "",
                                "duration": "14th Nov 24 to 19th Nov 24 (3 day or 5 day)",
                                "numberOfClasses": "",
                                "registrationLink": "https://forms.gle/nm8XUF7rLMVtHF3z5",
                                "noPriceDisplayTextLink" : "https://drive.google.com/file/d/1j2CNluBk1NdAgnS8UxjWVg9Z9vGXPiRS/view?usp=sharing"
                            }
                        },
                    ]
                },
                {
                    "id": "0f20db85-6ad3-400e-a056-bae93047a55a",
                    "displayId": "cali-retreat-feb25",
                    "name": "Cali Escape Retreat 2.0",
                    "productType": "WORKSHOP",
                    "isArchived" : false,
                    "images": [
                        "/images/programs/calisthenics/retreat/cali-retreat-2.png"
                    ],
                    "description": "Move out of the reel world and create real connections. Step out of your comfort zone into an enriching experience in the middle of an untouched natural environment along with practicing Calisthenics, Yoga and Swimmingwith our expert trainers",
                    "descriptionSummary": "Let's disconnect from reel world, to connect in real world.",
                    "summaryBulletPoints": [
                        "Learn Calisthenics, Yoga & Swimming",
                        "Live in an untouched environment"
                    ],
                    "bulletPoints": [
                        "Venue: Varanashi Organic Farms, Adyanadka, Karnataka (near Mangalore)",
                        "1 day or 3 days or 5 days option",
                        "Open to All Genders & Age groups",
                        "Open to all levels: Beginner or Intermediate or Advance",

                    ],
                    "tags": [
                        "calisthenics-workshop"
                    ],
                    "testimonials": [
                        
                    ],
                    "faqs": [
                        
                    ],
                    "auxiliaryData": {
                        "costSuffix": "",
                        "registrationLink": "https://forms.gle/9nCBnmbkPHDjMG7EA"
                    
                    },
                    "variants": [
                        {
                            "id": "90a86a27-56b7-40af-9bb5-bae93047a44b",
                            "displayId": "cali-retreat-feb25",
                            "noPriceDisplayText": "Packages starting at 7500",
                            "discount": 0,
                            "stock": Infinity,
                            "auxiliaryData": {
                                "costSuffix": "",
                                "timings": "",
                                "duration": "5th to 9th Feb or 12th to 16th Feb",
                                "numberOfClasses": "",
                                "registrationLink": "https://forms.gle/9nCBnmbkPHDjMG7EA",
                                "noPriceDisplayTextLink" : "https://drive.google.com/file/d/1f2kq_JnW3WiUhVHZE_xpfu_ybo4cbK7e/view?usp=drive_link"
                            }
                        },
                    ]
                },
            ]
        },
        "testimonialSection": {
            "title": "Testimonials",
            "testimonials": [
                {
                    "user": {
                        "firstName": "Hansini",
                        "lastName": "Maru",
                        "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocJ9deotKgYX7HmY-W9OqfwSS5YWmSrQ9Kgx_aHOFYkb=w45-h45-p-rp-mo-br100",
                        "designation": "Student"
                    },
                    "testimony": "I joined @grip it by srushti during lockdown when I wasn't regular and disciplined with my workouts, so I joined the morning batch with quite much excitement, watching at what all stuff she makes people do during online trainings, it's been 4-5 months now and I have come a long way learning lot of different stuff. When I first got a pull up bar I had no idea why else do with it then to just hang and today I can do 30+ 3/4 pulls, from barely doing 2-3 pushups with a good form, I have now acquired a much better form with more no of reps, I feel so good to see the way I have progressed be it handstands, head stands or any other thing, it makes you happy when you see a better version of yourself then of what you were yesterday. All credits to her for keeping us extremely motivated, and pushing us to our max limits each day. YES we have sore muscles after doing 500 reps of legs, or after doing a session which she said was going to be very easy, but it's all worth it!",
                    "rating": 5,
                    "tags": [
                        "calisthenics-live","cali-all"
                    ],
                },
                {
                    "user": {
                        "firstName": "Riya",
                        "lastName": "Dedhia",
                        "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjWqGfJ8f9HWY6DZLakwphovy-k2wCZa7I3S1wdpqq-j-qW_=w120-h120-p-rp-mo-br100",
                        "designation": "Nutritionist, Dietician"
                    },
                    "testimony": "I had joined the full body workout with Srushti to set a daily routine for my fitness. But eventually I gained much more than I had expected. She focuses on individual posture and corrects our form in the class itself. My muscle strength and endurance has improved drastically. From inclined push up to floor push up in 3 months proves it all. Not only does she focuses on strength training, but also on flexibility and skills. She has good knowledge of the muscles involved and the right technique. Thank you so much Srushti for personalised attention even in such a large group. I would recommend all the young adults who wish to reach their fitness goal to get trained under Srushti.",
                    "rating": 5,
                    "tags": [
                        "calisthenics-live","cali-all"
                    ],
                },
                {
                    "user": {
                        "firstName": "Abhiruchi",
                        "lastName": "",
                        "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjVu2MniIQg7IT6XgR8DpA-WSr4oIvxf-BrfDqvXj68F7sc=w120-h120-p-rp-mo-br100",
                        "designation": ""
                    },
                    "testimony": "I enrolled for the push up program with Srushti and by the end of the dedicated  12 workouts I couldn’t believe how the program was so strategically and dynamically structured that I could actually hit a full push up which had been my dream for a long long time. Srushti was patiently available to guide every step of the way and taught me the sheer power of consistency with her fundamentals-focused workouts. I am beyond impressed and grateful. This is the best program to learn these crucial basics and I applaud Srushti for her crazy and unwavering dedication towards her students. Thanks a ton.",
                    "rating": 5,
                    "tags": [
                        "calisthenics-guided", "calisthenics-workshop","cali-all"
                    ],
                },
                {
                    "user": {
                        "firstName": "Mona",
                        "lastName": "Parmar",
                        "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocK9paQdapSX_t4c2NT1aHVJfcFDPrScBUp34C1p91kBUxVdmg=w75-h75-p-rp-mo-br100",
                        "designation": ""
                    },
                    "testimony": "Due to certain circumstances and leg injury, i was not able to join a regular live morning fitness class, but My friend told me about Srushti's push up programme long back. I had one on one call with her to check my fitness level. The push up programme includes written workouts you can do on your own time which is absolutely convenient for me being a mom. She is regular with checking the videos and providing valuable feedback. All the workouts are tailor made according to your fitness level and goals. It follows a standard combination of skill progression exercises So, highly recommended if you want to unlock your push up or increase the reps or rom if you don't have time to attend online live classes.",
                    "rating": 5,
                    "tags": [
                        "calisthenics-guided", "calisthenics-workshop","cali-all"
                    ],
                },
                {
                    "user": {
                        "firstName": "Gunvanti",
                        "lastName": "Gala",
                        "profileImage": "https://lh3.googleusercontent.com/a/ACg8ocIMttAEyaDOuSVEgcHFDzvnOXTgsYoSNNk84mv3ReGf=w75-h75-p-rp-mo-br100",
                        "designation": ""
                    },
                    "testimony": "I am Gunvanti Gala. I am 62 years old house wife staying in Matunga, Mumbai . Last few years have been very tough for me due to many complication in my health.  During lockdown due to the ongoing crisis in those days in world and due to all negative news coming from around the world i fell into depression and i was worried for the health of my family members  all the time .I lost my balance and fell in the house one  day and got multiple fractures in the body . Cracks in my body was so minor and in such places that doing plaster was not possible.after many many days  of treatment one fine day test showed that i had osteoporosis which made my bones very weak and delicate like a small baby. My shoulders became so weak that I could not pick up 1 kg weight in my hand .i had to face new problem after every few days .Then i met my saviour  Ekta shah. Ekta shah was very kind and patient.She started curing me step by step . She Decided to treat my Mental heath first and then physical health . And again my journey towards healthy life began.During one month of counselling i started gaining confidence slowly and withing few weeks i was all set to start my physical fitness.  Ekta started my strength training and her systematic exercise program helped me a lot . I had weak shoulder ,back and legs. Earlier i could not stand for more than 15 mins and now ii can walk for one hour . I dont need back support anymore while sitting on chair .i can lift my legs now. I can pick up heavy weights like before now.  I am very thankful to Ekta shah and i am blessed to hv her as my fitness coach.",
                    "rating": 5,
                    "tags": [
                        "yoga"
                    ],
                },
                {
                    "user": {
                        "firstName": "Khushbu",
                        "lastName": "Karia",
                        "profileImage": "https://lh3.googleusercontent.com/a-/ALV-UjWqcBkxc9ArIgJHmPKwxG9cPjFib5yb9Q-4coSXFGKOBA=w75-h75-p-rp-mo-br100",
                        "designation": ""
                    },
                    "testimony": "Ekatvam yoga classes are hands down the best yoga classes! The one thing I love about Ekta miss is her dedication and knowledge about yoga. She communicates so well and makes you feel very comfortable and an active part of it. She encourages all the students to do better and this has helped me a lot. I never imagined to be able to do what she helped me learn and practice. The one hour in a day I spent doing yoga with Ekta miss was the best hour of my day. I got everything I needed, a little bit of work-out to break some sweat, strong stretch in sore muscles and deep relaxation at the end. I always thought that my body is not made for yoga, too stiff, but Ekta miss patiently worked with me to help me relax and make sure I did the yoga poses correctly.  I’ve been practicing yoga with Ekta miss for a year now and I can definitely observe noticeable differences in my body, I gained so much flexibility and strength! I highly recommend ekatvam yoga classes to everyone , so that many people can benefit from it!",
                    "rating": 5,
                    "tags": [
                        "yoga"
                    ],
                }
            ],
        },
    },
    "aboutUsPageData": {
        "title": "About Us",
        "detailsSection": {
            "title": "About Grip It",
            "image": "/images/aboutus/aboutus.png",
            "description": `When we were all stuck in the lockdown, Calisthenics came to our rescue as the only equipment it needed was consistency and dedication and could be easily done at home. To spread the same, our founder got her certification as Calisthenics Coach and with her experience in the field already started with the Push Up Workshop, which received immense respone leading to houseful batches one after the other, followed by introduction of new programs like Full Body Strength Training Batches, Pull Up Training, Skill Workshops, Personal Training and Customised Workouts.
            <br><br> Alongside she was studying for her BTech in Computer Engineering and worked in corporate for 1.5 years, but her grit got her back to the coaching world and a new phase of Grip It started as she left her corporate job to spread Calisthenics as much as possible as a way to incorporate a fitter you and healthier lifestyle, realizing the best investment made by one is in their health.`,
            "owner": {
                "title": "About our founder",
                "anchorText": "Train with Srushti Rajesh Shah",
                "anchorLink": "/programs",
                "image": "/images/aboutus/founder.jpg",
                "description": `Srushti Rajesh Shah found her passion for sport at the age of 10, while playing Kho Kho in her school, ever since then there has been no going back, one sport after the other from athletics to football to mountaineering, having done some of the toughest climbs of her life at a young age and securing an A grade in the Advance Mountaineering Course from NIM, now she has settled with Calisthenics having 7 years experience as an bodyweight-strength training athlete and 3+ years as an Certified Calisthenics Coach.
                <br>She is the first female freestyler from India to represent the country at the WSWCF World Championship 2022. She has  played a pioneering role in promoting the Calisthenics Community of India and growing the sport among the woman of the country and outside with her initiative Calisthenics Girls India. As a part the initiative she has organised All Woman’s Strength Meet Up in 5 cities across India (Mumbai, Delhi, Pune, Bangalore, Surat) with over 200 participants and the first ever All Woman's Calisthenics Championship.
                <br>Being one of the first females to start freestyling in India, she has secured the 1st Position in WSWCF National Championship held at Pune in July 2022, the Runner Up Position at All India Strength Wars 4.0 held in November 2022 and has been the winner of Elysium Street Workout Championship in 2022 and 2023. Secured the 1st Position at MuscleBar Barbarian Championship in freestyle in 2023 and participated and won various other competition.
                <br>With over 900k subscribers on YouTube and 250k+ Instagram followers she has worked with various brands namely The Souled Store, Ultrahuman HQ, Muscle Blaze, Kica Active and more to create content and spread the importance of fitter and healthier life.`
            },
            "vision": {
                "title": "Vision",
                "anchorText": "Join Us",
                "anchorLink": "/programs",
                "description": "At Grip It, we dream of being the go-to place for anyone starting or advancing in their fitness journey. We want to make it easy with our well-structured programs and live online classes with personalized attention for individuals to achieve new levels . Our vision is to make calisthenics and bodyweight training cult to help people get stronger and learn awe-inspiring skills",
            },
            "mission": {
                "title": "Mission",
                "anchorText": "Join Us",
                "anchorLink": "/programs",
                "description": [
                    "Provide quality teaching under certified professionals to encourage individuals to to adopt healthier lifestyles and enhance both their physical and mental well-being.",
                    "We aim to cultivate Calisthenics as a leading workout choice in India and worldwide. This accessible form of exercise requires no equipment and is easy to start, making fitness achievable for everyone.",
                    "We strive to promote online home workouts, addressing the challenges faced by those unable to travel to the gym or struggling to integrate workouts into their busy work schedules. By making fitness accessible at home, we empower individuals to prioritize their health conveniently."
                ]
            },
        },
        "qualificationsSection": {
            "isHidden": "true",
            "title": "Qualifications",
            "description": "We are a team of dedicated fitness enthusiasts who are passionate about helping you achieve your fitness goals",
            "qualifications": [
                {
                    "title": "Certified Calisthenics Coach",
                    "description": "We are a team of dedicated fitness enthusiasts who are passionate about helping you achieve your fitness goals",
                    "image": "https://dummyimage.com/50x50",
                },
                {
                    "title": "Certified Personal Trainer",
                    "description": "We are a team of dedicated fitness enthusiasts who are passionate about helping you achieve your fitness goals",
                    "image": "https://dummyimage.com/50x50",
                }
            ],
        },
        "recognitionSection": {
            "title": "Recognition",
            "description": "We are a team of dedicated fitness enthusiasts who are passionate about helping you achieve your fitness goals",
            "recognitions": [
                {
                    "title": "First Female Freestyler to represt India at World Championship",
                    "description": "Srushti Rajesh Shah participated in the WSWCF World Championship 2022 in Latvia, Riga in the freestlye LW and MW categort and finished 8th",
                    "image": "/images/aboutus/wswcf.jpg",
                    "link": "https://www.youtube.com/watch?v=061TB-RaaNA",
                    "linkText": "Learn more"
                },
                {
                    "title": "Featured in the Australian Just Breathe Magazine",
                    "description": "As her initiative to run Calisthenics Girls India page and organize event for female around the country, she got features in the 55th edition of Australia's Just Breathe Magazine",
                    "image": "/images/aboutus/breathemagazine.jpg",
                    "link": "/about-us",
                    "linkText": "Learn more"
                },
                {
                    "title": "India Today on International Women's Day",
                    "description": "Being one of the pioneers in the history of calisthenics in India, she spoke about Calisthenics and the initiative to grow it in India and Grip It with India Today on International Women's Day 2023",
                    "image": "/images/aboutus/indiatoday.png",
                    "link": "https://twitter.com/IndiaToday/status/1629497842065870850",
                    "linkText": "Learn more"
                },
                {
                    "title": "The Bridge: On National Sports Day featured in Myntra's #UnitedInSports campaign",
                    "description": "This National Sports Day, let's remind ourselves the need for sporting activities in life. Mountaineer Srushti Rajesh Shah speaks about how she began as a runner and then how her quest of climbing mountains took her to Callisthenics",
                    "image": "https://thebridge.in/h-upload/2022/08/30/37088-screenshot-2022-08-30t120513522.webp",
                    "link": "https://thebridge.in/mountaineering/mountaineer-srushti-shah-running-callisthenics-34998?infinitescroll=1",
                    "linkText": "Learn more"
                },
                {
                    "title": "Gujarti Midday: CGI MeetUp by Srushti",
                    "description": "A unique initiative by a Gujarati woman to provide free calisthenics training to women in 6 different cities across India",
                    "image": "https://middaycdn.s.llnwi.net/Image_GMD/images/2022/jun/WhatsApp%20Image%202022-06-13%20at%2012.15.45%20PM.jpeg",
                    "link": "https://www.gujaratimidday.com/lifestyle-news/health-tips/photo/gujarati-srushti-shah-held-super-fitness-all-india-womens-calisthenics-meet-to-provide-free-training-women-10715/1",
                    "linkText": "Learn more"
                },
                {
                    "title": "Mint Lounge Article: Is calisthenics India's newest fitness and sports cult?",
                    "description": "",
                    "image": "https://images.livemint.com/img/2022/07/21/1140x641/Fitness_Calisthenics_1658410199191_1658410240252_1658410240252.jpg",
                    "link": "https://lifestyle.livemint.com/health/fitness/is-calisthenics-india-s-newest-fitness-and-sports-cult-111658409022714.html",
                    "linkText": "Learn more"
                },
                {
                    "title": "Gujarti Midday: Represent India",
                    "description": "Women's Day 2023: Srushti from Mumbai to fly India's flag in Calisthenics World Cup",
                    "image": "https://middaycdn.s.llnwi.net/Image_GMD/images/2023/mar/Srushtii111_d.jpg",
                    "link": "https://www.gujaratimidday.com/news/mumbai-news/photo/international-women-s-day-2023-calisthenics-girl-from-mumbai-srushti-shah-wants-to-represent-india-in-calisthenics-world-cup-and-win%C2%A0-11548/1",
                    "linkText": "Learn more"
                },
                {
                    "title": "9xm Music Video Feature",
                    "description": "9xm new initiative Fit Bano had Srushti featured in their promo video.",
                    "image": "/images/aboutus/9XM.png",
                    "link": "https://youtu.be/0RQdQhk_cv0",
                    "linkText": "Learn more"
                },
                {
                    "title": "DCN Pune Media: Featured CGI Meet Up",
                    "description": "",
                    "image": "/images/aboutus/dcnpune.jpg",
                    "link": "https://www.instagram.com/tv/CefxFx9Aqc0/?igsh=MXJsejlucnNnbm02dw==",
                    "linkText": "Learn more"
                },
                {
                    "title": "Featured on People Are Awesome Video",
                    "description": "",
                    "image": "/images/aboutus/peopleareawesome.png",
                    "link": "https://www.instagram.com/reel/CrlmMbKvIpH/?igshid=MzRlODBiNWFlZA==",
                    "linkText": "Learn more"
                },
                {
                    "title": "Won the Iconic Award at the AISW 4.0 ",
                    "description": "Awared the Iconic Award for her contributions towards the Calisthenics Girls India Community.",
                    "image": "/images/aboutus/iconic.png",
                    "link": "",
                    "linkText": "Learn more"
                },
                {
                    "title": "Participated and won various National Calisthenics Championship around the country in the Freestyle Category",
                    "description": "",
                    "image": "/images/aboutus/national.JPG",
                    "link": "",
                    "linkText": "Learn more"
                },
                {
                    "title": "Worked with various brands",
                    "description": "Kica, Souled Store, Coca Cola, Ultrahuman, Plaeto, RAW Pressery, TurnTurtle, MuscleBlaze",
                    "image": "/images/aboutus/brand.jpg",
                    "link": "",
                    "linkText": "Learn more"
                },
                {
                    "title": "Featured on Squat University Page",
                    "description": "",
                    "image": "/images/aboutus/squatuni.png",
                    "link": "https://www.youtube.com/watch?v=OCloSjfTnjU",
                    "linkText": "Learn more"
                },
                {
                    "title": "First Indian at SWUB",
                    "description": "Our founder become the 1st Indian to represent India at the SWUB VI, one of the biggest Calisthenics Competition.",
                    "image": "/images/aboutus/swub.jpg",
                    "link": "",
                    "linkText": "Learn more"
                },
                {
                    "title": "HerCircle Feature",
                    "description": "Her Circle, founded by Nita M Ambani, is an inclusive, collaborative, diverse, interactive, socially-conscious digital movement for the women of India",
                    "image": "/images/aboutus/hercirclelog.png",
                    "link": "https://www.instagram.com/p/C9CvLE5C0yI/",
                    "linkText": "Learn more"
                },
                {
                    "title": "RedFM Feature",
                    "description": "RJ Nidhi featured us on her show Morning No.1 Red fm Bajaate Raho!!",
                    "image": "/images/aboutus/redfm.jpg",
                    "link": "https://www.instagram.com/p/C3bWj3ciH2M/",
                    "linkText": "Learn more"
                },


            ],
        }
    },
    "bannerData": {
        "mobileTitle": "Join our Online Calisthenics Class",
        "desktopTitle": "We have introduced new batches! Get started now.",
        "linkTo": "/programs/fbw/fbw-5-days-1-month",
        "linkText": "Join Now",
    },
    "productsPageData": {
        "title": "Products",
        "description": "Explore our extensive selection of fitness-related products and discover exciting collaborations in the world of health and wellness. Check out some highlights from our product offerings and partnerships below.",
        "products": [
            {
                "image": "/images/products/hangboard.jpeg",
                "title": "Hangboard",
                "description": "Let's now play with different grips😍, stronger pulls, and build grip✊ and forearm strength🦾",
                "link": "https://forms.gle/pFtKcH1GAgwenkyB9",
                "linkText": "Buy Now",
            },
            {
                "image": "/images/products/sthenos.jpg",
                "title": "Pull Up Bar, rings, resistance band with customisation",
                "description": "Let's smash those Pull Ups and Muscle Ups😍🥳🤸🏻‍♀️.",
                "link": "https://www.sthenos.co.in/?ref=ssrushti",
                "linkText": "Buy Now! Use the code SRUSHTI10 to get 10% off",
            },
            {
                "image": "/images/products/kica.jpg",
                "title": "Active Wear",
                "description": "Buy Active Wear from kica active and get flat 10% off using coupon code SRUSHTI25.",
                "link": "https://kicaactive.com/",
                "linkText": "Get Flat 25% Discount Now Sitewide. Using Code SRUSHTI25",
            },
            {
                "image": "https://koshayoga.co/cdn/shop/files/272884800_1637070833309303_3146509620390351049_n_bd68987e-eb19-4e4f-b30f-aa347ad5897c_720x.jpg?v=1657819588",
                "title": "Yoga Accessories",
                "description": "Buy all yoga accessories like blocks, mats, wheels and more get flat 10% off using coupon code SRUSHTI10.",
                "link": "https://koshayoga.co/discount/srushti10/",
                "linkText": "Get Flat 10% Discount Now Sitewide. Using Code SRUSHTI10",
            }
        ]
    },
    "footerData": {
        "whatsappCTA": {
            "title": "Connect with us on WhatsApp",
            "description": "Join our WhatsApp group to stay up-to-date on the latest news and events.",
            "link": "https://wa.me/919410385170?text=Hi%2C%0AI%20want%20to%20learn%20more%20about%20programs%20offered%20by%20Grip%20It",
            "linkText": "Join Now"
        },
        "footerSection": [
        /*    {
                "title": "Products",
                "links": [
                    {
                        "title": "Buy Hangboard Here",
                        "url": "/products"
                    },
                    {
                        "title": "Get 30% off on kica active",
                        "url": "/products"
                    },
                    {
                        "title": "Buy Pull Up Bar Here",
                        "url": "/programs"
                    }
                ]
            },*/
            {
                "title": "Programs",
                "links": [
                    {
                        "title": "Push Up Program",
                        "url": "/programs"
                    },
                    {
                        "title": "Pull Up Program",
                        "url": "/programs"
                    }
                ]
            },
            {
                "title": "About Us",
                "links": [
                    {
                        "title": "About Srushti",
                        "url": "/about-us"
                    },
                    {
                        "title": "Instagram: @ssrushti",
                        "url": "https://instagram.com/ssrushti"
                    },
                    {
                        "title": "Instagram: @gripit.fit",
                        "url": "https://instagram.com/gripit.fit"
                    },
                    {
                        "title": "Youtube: @ssrushti",
                        "url": "https://www.youtube.com/@ssrushti"
                    },
                    {
                        "title": "Email: gripitbysrushti@gmail.com",
                        "url": "mailto:gripitbysrushti@gmail.com"
                    }
                ]
            },
            {
                "title": "Terms & Conditions",
                "links": [
                    {
                        "title": "Terms & Conditions",
                        "url": "/terms-and-conditions#terms-and-conditions"
                    },
                    {
                        "title": "Privacy Policy",
                        "url": "/terms-and-conditions#privacy-policy"
                    },
                    {
                        "title": "Refund Policy",
                        "url": "/terms-and-conditions#refund-policy"
                    }
                ]
            }
        ],
    },
    "termsAndConditionsPageData": {
        "termsAndConditionsSection": {
            "title": "Terms and conditions",
            "description": "Please read the terms and conditions carefully",
            "termsAndConditions": [
                `By using this website, you agree to the following terms and conditions. Please read them carefully before using this website.
                This website is operated by Grip It. Throughout the site, the terms “we”, “us” and “our” refer to Grip It. Grip It offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
                Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
                Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                You may not use our services for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright).
                You must not transmit any worms or viruses or any code of a destructive nature.
                A breach or violation of any of the Terms will result in an immediate termination of your Services.`,
                `We reserve the right to refuse service to anyone for any reason at any time.
                You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
                The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.`,
                `We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.`,
                `We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.`,
                `Certain services may be available exclusively online through the website.
                We have made every effort to display as accurately as possible the colors and images of our services that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
                We reserve the right, but are not obligated, to limit the sales of our Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any services that we offer. All descriptions of service pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any service at any time. Any offer for any service made on this site is void where prohibited.
                We do not warrant that the quality of any services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.`,
                `We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address.
                In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                `,
                `We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
                You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
                We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.`,
                `Certain content, services available via our Service may include materials from third-parties.
                Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, or services of third-parties.
                We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party should be directed to the third-party.`,
                `If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.`
            ]
        },
        "refundPolicySection": {
            "title": "Refund policy",
            "description": "Please read the refund policy carefully",
            "refundPolicy": [
                "Program fee once paid can't be refunded or transferred",
                "We don't gurantee any results once you join our program. Results completely depend on your efforts and consistency"
            ]
        },
        "privacyPolicySection": {
            "title": "Privacy policy",
            "description": "Please read the privacy policy carefully",
            "privacyPolicy": [
                `Information that is gathered from visitors:
                In common with other websites, log files are stored on the web server saving details such as the visitor's IP address, browser type, referring page and time of visit.
                Cookies may be used to remember visitor preferences when interacting with the website.
                Where registration is required, the visitor's email and a username will be stored on the server.`,
                `How the Information is used: 
                The information is used to enhance the vistor's experience when using the website to display personalised content and possibly advertising.
                E-mail addresses will not be sold, rented or leased to 3rd parties.
                E-mail may be sent to inform you of news of our services or offers by us or our affiliates.     
                `,
                `Visitor Options:
                If you have subscribed to one of our services, you may unsubscribe by following the instructions which are included in e-mail that you receive.
                You may be able to block cookies via your browser settings but this may prevent you from access to certain features of the website.
                `,
                `Cookies:
                Cookies are small digital signature files that are stored by your web browser that allow your preferences to be recorded when visiting the website. Also they may be used to track your return visits to the website.
                3rd party advertising companies may also use cookies for tracking purposes.
                `,
                `Google Ads:
                Google, as a third party vendor, uses cookies to serve ads.
                Google's use of the DART cookie enables it to serve ads to visitors based on their visit to sites they visit on the Internet.
                Website visitors may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.`
            ]
        },
    },
    "blogsData": {
        allBlogs: [
            {
                id: 1,
                title: "Title: The Transformation of Alex Turner",
                excerpt: `
Chapter 1: The Wake-Up Call

Alex Turner was your average thirty-something office worker, residing in a small suburban town. His life revolved around a monotonous routine: wake up, commute to work, spend eight hours in front of a computer, and then return home, exhausted. Fast food was a staple in his diet, and weekends were often spent on the couch binge-watching TV shows. His friends often joked that he was “one pizza slice away from rolling out of the door,” but Alex never took their comments seriously. That is, until one fateful day.

It was a Friday afternoon, and Alex had just returned home after a long week at work. As he bent down to tie his shoelaces to go for a short jog—a habit he had abandoned months ago—he felt a sharp pain in his chest. Panic surged through him as he gasped for air, clutching his heart. His wife, Jessica, rushed to his side, dialing 911 as Alex sank to the floor.

After a harrowing ride to the hospital, the doctors ran a series of tests and informed him that he had experienced a mild heart attack. The news struck him like a lightning bolt. At thirty-four years old, he was facing health issues usually reserved for much older individuals. The doctor’s stern words echoed in his mind, “If you don’t make drastic changes in your lifestyle, this will not be your last visit.”

As he lay in the hospital bed, the gravity of the situation sunk in. He realized he was on a dangerous path and had to make changes not just for himself but for his family. The fear of leaving Jessica and their two young children behind fueled a fire within him that had long been dormant.

Chapter 2: The Decision

After being discharged from the hospital, Alex faced the daunting task of changing his life. He knew it wouldn’t be easy, but he was determined. One evening, he sat down with Jessica, discussing their options. They made a pact to embark on a health journey together. Jessica promised to support him in any way she could, whether through cooking healthier meals or joining him in physical activities.

Alex started by re-evaluating his diet. He turned to the internet for guidance, poring over articles and videos about healthy eating. He discovered the benefits of whole foods, lean proteins, fruits, and vegetables. The first week was challenging; his cravings for greasy fast food were hard to resist. But with Jessica’s encouragement and a newfound sense of purpose, he pushed through.

He began to track his meals using a nutrition app, ensuring he consumed a balanced diet. Gradually, he replaced his go-to takeout meals with homemade versions. Instead of ordering pizza, he learned to make whole wheat pizzas topped with fresh vegetables and lean chicken. With each healthy meal, he felt a sense of accomplishment and a boost in his energy levels.

Chapter 3: The Fitness Journey Begins

With his diet under control, it was time for Alex to address the fitness component. He dusted off his running shoes and ventured outside for the first time in months. The initial runs were tough; his body protested with every step. He struggled to run even a mile without stopping, feeling as if his lungs were on fire. But he remembered the doctor’s words and pushed through the discomfort.

As days turned into weeks, Alex started to see small improvements. He gradually increased his running distance and even started to incorporate strength training into his routine. He followed online workouts and created a schedule that balanced cardio and weightlifting. Every time he completed a workout, he felt a sense of pride that fueled his motivation.

However, it wasn’t just about the physical changes; Alex noticed mental changes too. Exercise became his stress reliever, a way to clear his mind after a long day. He found solace in the rhythm of his feet hitting the pavement and the feeling of weights in his hands. The endorphins released during workouts made him feel alive, a stark contrast to the lethargy that had plagued him for years.

Chapter 4: Building a Community

As Alex continued his journey, he sought out like-minded individuals who shared his goals. He joined a local running club and participated in group workouts at the community center. Meeting new people who were also striving for health inspired him to push harder. They exchanged tips, shared recipes, and supported one another through challenges.

One day, during a group run, Alex met Sarah, a personal trainer who had transformed her life after overcoming her own struggles with weight. Sarah’s story resonated with him; she had once been over 250 pounds and had turned her life around through dedication and hard work. She offered to mentor Alex, providing him with personalized workout plans and nutritional advice.

Under Sarah’s guidance, Alex’s progress accelerated. He learned the importance of setting specific goals and tracking his achievements. He began participating in 5K races, pushing himself to improve his time with each event. The thrill of crossing the finish line ignited a competitive spirit within him that he never knew existed.

Chapter 5: The Setbacks

Despite his progress, Alex faced setbacks along the way. Life threw challenges at him, and balancing work, family, and fitness became overwhelming at times. There were days when he didn’t feel like working out or when he succumbed to old habits, indulging in a slice of cake during a family celebration. Guilt washed over him, and he found himself spiraling into self-doubt.

One particularly challenging week, Alex got sick with a severe cold that knocked him out for nearly two weeks. The break from exercise made him feel defeated, and he struggled to get back into his routine. Jessica reminded him of how far he had come, encouraging him to focus on the progress rather than the setbacks.

After the illness, Alex returned to the gym with renewed determination. He took it slow, allowing his body to readjust to the workouts. He learned the importance of being kind to himself and understanding that setbacks were a part of the journey. Each time he faced a hurdle, he grew stronger, both physically and mentally.

Chapter 6: The Breakthrough

Months passed, and Alex’s transformation was undeniable. He had lost over fifty pounds, gained muscle, and his energy levels skyrocketed. People at work began to notice the change in him, complimenting his appearance and asking about his secret. He proudly shared his journey, encouraging others to take charge of their health.

One day, Sarah approached Alex with an exciting opportunity. There was a local half-marathon coming up, and she believed he had the potential to compete. At first, Alex was hesitant. The thought of running thirteen miles seemed daunting, but Sarah’s belief in him ignited a spark of courage.

After much contemplation, he decided to take on the challenge. He began a rigorous training plan, incorporating longer runs and building his stamina. Each week, he pushed himself to new limits, and the fear that once held him back transformed into excitement.

Chapter 7: Race Day

The day of the half-marathon arrived, and Alex felt a mix of nerves and anticipation. He stood at the starting line, surrounded by runners of all ages and skill levels. The atmosphere buzzed with energy, and he could feel the excitement in the air. As the race began, he took a deep breath and reminded himself of the countless hours of hard work that had led him to this moment.

With each mile marker he passed, he reflected on his journey—the struggles, the triumphs, and the unwavering support from Jessica and his friends. As he reached the final stretch, fatigue threatened to take over, but he pushed through, recalling the fear that had motivated him to change his life.

As he crossed the finish line, tears of joy streamed down his face. He had done it. He had transformed not just his body but his entire life. The sense of accomplishment was overwhelming. The cheers from the crowd and the medal draped around his neck symbolized the victory over his past self.

Chapter 8: A New Beginning

In the months following the half-marathon, Alex continued to set new goals. He embraced a lifestyle centered around health and wellness, inspiring others along the way. He started a blog to share his experiences, offering tips and motivation to those looking to transform their lives.

Jessica joined him in his fitness journey, and they often worked out together, creating a strong bond that flourished through their shared experiences. They began to participate in charity runs and community events, promoting health awareness in their town.

Alex also became an advocate for heart health, sharing his story with local schools and organizations. He spoke about the importance of prevention and encouraged young people to make healthy choices. His journey had come full circle; he was no longer just surviving but thriving.

Chapter 9: Reflections

Looking back on his transformation, Alex felt immense gratitude. He had faced his demons and emerged stronger, not just physically but mentally and emotionally. The journey had taught him valuable lessons about resilience, determination, and the importance of community.

As he stood in front of the mirror one evening, he marveled at the reflection staring back at him. The man he saw was not just fit but confident and full of life. He understood that the journey was ongoing; it wasn’t just about reaching a destination but embracing a lifestyle of health and wellness.

Chapter 10: Legacy

Alex’s story inspired many, proving that change is possible at any stage in life. He realized that he had not just transformed his own life but had the power to influence others positively. His children watched as he prioritized health, instilling the values of fitness and nutrition in them from an early age.

With Jessica by his side, they became a family committed to living a healthy lifestyle. They explored new activities together, from hiking in the mountains to cycling along scenic trails. Every experience was an opportunity for bonding and creating lasting memories.

As Alex looked to the future, he knew that the journey of health and wellness would continue
`,
                image: "https://picsum.photos/seed/fitness1/800/600",
            },
            {
                id: 2,
                title: "10 Best HIIT Workouts to Burn Calories Fast",
                excerpt: "HIIT (High-Intensity Interval Training) workouts are designed to help you burn calories quickly. Here are ten of the best HIIT exercises...",
                image: "https://picsum.photos/seed/fitness2/800/600",
            },
            {
                id: 3,
                title: "Nutrition Tips for Muscle Gain",
                excerpt: "Gaining muscle requires a balanced diet with the right nutrients. Discover key foods and meal planning tips to support muscle growth...",
                image: "https://picsum.photos/seed/fitness3/800/600",
            },
            {
                id: 4,
                title: "Yoga for Flexibility: Top Poses to Start",
                excerpt: "Yoga isn’t just about relaxation; it’s a great way to improve flexibility. Here are some beginner-friendly yoga poses to increase mobility...",
                image: "https://picsum.photos/seed/fitness4/800/600",
            },
            {
                id: 5,
                title: "How to Build a Home Gym on a Budget",
                excerpt: "Creating a home gym doesn’t have to be expensive. Here are some tips and equipment recommendations for building a gym at home...",
                image: "https://picsum.photos/seed/fitness5/800/600",
            },
            {
                id: 6,
                title: "The Importance of Rest Days in Your Workout Routine",
                excerpt: "Rest days are crucial for muscle recovery and overall fitness. Find out why you need them and how they can benefit your workout progress...",
                image: "https://picsum.photos/seed/fitness6/800/600",
            },
            {
                id: 7,
                title: "Beginner’s Guide to Running: How to Start and Keep Going",
                excerpt: "Running is a simple, effective form of cardiovascular exercise. Learn tips to start running and stay motivated for long-term success...",
                image: "https://picsum.photos/seed/fitness7/800/600",
            },
            {
                id: 8,
                title: "Top 5 Exercises for Core Strength",
                excerpt: "A strong core is essential for balance and stability. Here are five effective exercises to strengthen your core muscles...",
                image: "https://picsum.photos/seed/fitness8/800/600",
            },
            {
                id: 9,
                title: "Hydration Tips for Active Lifestyles",
                excerpt: "Staying hydrated is essential for anyone with an active lifestyle. Discover how much water you need and tips for maintaining hydration...",
                image: "https://picsum.photos/seed/fitness9/800/600",
            },
            {
                id: 10,
                title: "Mental Health Benefits of Regular Exercise",
                excerpt: "Exercise isn’t just good for the body; it’s also great for mental well-being. Learn how physical activity can improve mood and reduce stress...",
                image: "https://picsum.photos/seed/fitness10/800/600",
            },
            {
                id: 11,
                title: "5 Quick Workouts for Busy People",
                excerpt: "Don’t have time for long workouts? Try these five quick, effective routines to stay fit, even with a packed schedule...",
                image: "https://picsum.photos/seed/fitness11/800/600",
            },
            {
                id: 12,
                title: "The Science Behind Muscle Recovery",
                excerpt: "Understanding muscle recovery can help you avoid injury and improve performance. Explore the science of how muscles repair and grow...",
                image: "https://picsum.photos/seed/fitness12/800/600",
            },
            {
                id: 13,
                title: "Top Nutrition Mistakes to Avoid for Weight Loss",
                excerpt: "Losing weight involves more than just calorie counting. Avoid these common nutrition mistakes for a more effective weight loss journey...",
                image: "https://picsum.photos/seed/fitness13/800/600",
            },
            {
                id: 14,
                title: "Essential Stretches to Do Before and After Exercise",
                excerpt: "Stretching helps prepare your body for exercise and reduce soreness afterward. Here are some essential pre- and post-workout stretches...",
                image: "https://picsum.photos/seed/fitness14/800/600",
            },
            {
                id: 15,
                title: "Cardio vs. Strength Training: Which Is Right for You?",
                excerpt: "Wondering whether to focus on cardio or strength training? Discover the benefits of each and find out which suits your fitness goals...",
                image: "https://picsum.photos/seed/fitness15/800/600",
            },
        ],
        }    
};
